@import './../../scss/variables';

.donor-card {
  $dc-h-gap: 1.5rem;
  transition:
    background-color 0.15s ease-in-out,
    color 0.15s ease-in-out;
  & + & {
    border-top: 1px solid var(--bs-border-color);
  }
  &:active,
  &:focus,
  &:hover {
    --bs-light-rgb: #{$tis-light-blue-rgb};
    --bs-dark-rgb: 255, 255, 255;
  }
  & > .hstack {
    column-gap: $dc-h-gap;
    row-gap: 0.25rem;
    & > * {
      &:after {
        content: '•';
        position: absolute;
        transform: translateX(calc($dc-h-gap / 2 - 50%));
        font-weight: $fw-regular;
      }
      &:last-child:after {
        content: none;
      }
    }
  }
}

.badge {
  &.flag-1 {
    --bs-border-color: #{$tis-orange};
  }
  &.flag-2 {
    --bs-border-color: #{$tis-dark-blue};
  }
  &.flag-3 {
    --bs-border-color: #{$tis-light-blue};
  }
}

.el-icon {
  position: relative;
  img {
    height: 24px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    label & {
      height: 20px;
    }
  }
  span {
    margin-left: 36px;
    label & {
      margin-left: 30px;
    }
  }
}

[data-direction] {
  display: inline-block;
  // border: 1px solid white;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-weight: $fw-bold;
  text-align: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin-right: 6px;
  &[data-direction='0'] {
    background-color: $tis-score-green;
  }
  &[data-direction='1'] {
    background-color: $tis-score-red;
  }
}
