.readmore {
  & > div {
    transition: max-height 0.5s ease-in-out;
    max-height: 1024px;
  }
  &.closed > div {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      pointer-events: none;
    }
  }
}
