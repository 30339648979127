@import './../../scss/variables';

.loading {
  &.big {
    min-height: 300px;
  }
  &.small {
    height: 100%;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $tis-orange;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $tis-orange;
    animation: lds-grid 1.2s linear infinite;
    &:nth-child(1) {
      top: 8px;
      left: 8px;
      animation-delay: 0s;
    }
    &:nth-child(2) {
      top: 8px;
      left: 32px;
      animation-delay: -0.4s;
    }
    &:nth-child(3) {
      top: 8px;
      left: 56px;
      animation-delay: -0.8s;
    }
    &:nth-child(4) {
      top: 32px;
      left: 8px;
      animation-delay: -0.4s;
    }
    &:nth-child(5) {
      top: 32px;
      left: 32px;
      animation-delay: -0.8s;
    }
    &:nth-child(6) {
      top: 32px;
      left: 56px;
      animation-delay: -1.2s;
    }
    &:nth-child(7) {
      top: 56px;
      left: 8px;
      animation-delay: -0.8s;
    }
    &:nth-child(8) {
      top: 56px;
      left: 32px;
      animation-delay: -1.2s;
    }
    &:nth-child(9) {
      top: 56px;
      left: 56px;
      animation-delay: -1.6s;
    }
  }
}

@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
