@use 'sass:map';
@import 'variables';
@import 'bootstrap_custom';
@import 'volby-common';

header {
  background-image: url('./../../public/img/parlament_2_color_1920_900.png');
  background-position-x: center;
  background-position-y: 20%;
}
